<template>
    <div class="border-r-2">
        <table class="table table-bordered border-r-2">
            <thead>
            <tr class="bg-1">
                <th rowspan="1" colspan="2" class="pos-r">
                    <div class="font-inter-medium text-center fs-lg-2">
                        Bidder Id <span>{{ details.participant_id }}</span>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody class="section-items">
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Name : </span>
                        <span>{{ details.name }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Company : </span>
                        <span>{{ details.company }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Email : </span>
                        <span>{{ details.email }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Mobile : </span>
                        <span>{{ details.mobile }}</span>
                    </div>
                </td>
            </tr>
            </tbody>
            <thead>
            <tr class="bg-1">
                <th rowspan="1" colspan="2" class="pos-r">
                    <div class="font-inter-medium text-center fs-lg-2">
                        Refund Details
                    </div>
                </th>
            </tr>
            </thead>
            <tbody class="section-items">
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Status : </span>
                        <span>{{ details.refund_status }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Refund ID : </span>
                        <span>{{ details.refund_id }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Refund Rate : </span>
                        <span>{{ details.refund_date }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Refund Authorised Officer : </span>
                        <span>{{ details.refund_authorized_officer }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Transaction ID : </span>
                        <span>{{ details.refund_transaction_id }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Documents : </span>
                        <span class="ml-3"><a @click="setDownload(details.refund_request_document)">View</a></span>
                        <span class="ml-3"><a @click="setDownload(details.refund_request_document)">Download</a></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Remarks : </span>
                        <span>{{ details.refund_remarks }}.</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name : 'BidderDetailsTable',

    props : {
        details : {
            type : Object
        }
    },

    methods : {
        setDownload (url) {
            if (url) {
                window.open(url);
            } else {
                alert('No file contains');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.section-items {
    tr {
        display: flex;
    }

    td {
        padding: var(--spacer-2) var(--spacer-4);
        width: 100%;
    }
}

</style>
