<template>
    <inner-page-card heading="Participant Bidder Details" :link-back="true">
        <div class="container" v-if="loading">
            <div class="card bg-info">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div class="container" v-else-if="error">
            <div class="card bg-danger fl-te-c">
                <div>
                    <error-icon/>
                    Failed to load data from the server.
                </div>
                <div>
                    <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                </div>
            </div>
        </div>

        <div v-else>
            <bidder-details-table :details="details" ></bidder-details-table>
        </div>

    </inner-page-card>
</template>

<script>
import BidderDetailsTable from '@/views/refund/BidderDetailsTable';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'ParticipantBidderDetails',

    components : { BidderDetailsTable },

    data () {
        return {
            loading : true,
            error   : false,

            details : null
        };
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            axios.form(urls.auction.refund.bidderRefundDetails, { id : that.$route.params.refund }).then(function (response) {
                const json = response.data;
                that.details = { ...json.data };
                that.loading = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        }
    }
};
</script>

<style scoped>

</style>
